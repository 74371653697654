<template>
  <!-- MENU -->
  <el-tabs v-model="activeTab">
    <el-tab-pane
      v-if="!noInstall"
      class="settings-panel"
      :label="$t('settings.integration.menu.installation')"
      name="1"
    >
      <div class="tab-header">
        <div class="tab-title">
          {{ $t('settings.integration.menu.install-title') }} {{ name }}
        </div>
        <div class="tab-desc bold">
          {{ $t('settings.integration.menu.install-step') }}
        </div>
      </div>
      <slot></slot>
    </el-tab-pane>

    <el-tab-pane
      v-if="features.includes('PREFERENCES')"
      class="settings-panel"
      :label="$t('settings.integration.menu.preferences')"
      name="2"
    >
      <div class="tab-header">
        <div class="tab-title">
          {{ $t('settings.integration.menu.preferences-title') }} {{ name }}
        </div>
        <div class="tab-desc">
          {{ $t('settings.integration.menu.preferences-step') }}
        </div>
      </div>
      <IntegrationSettingsPreference :preferencesAvailable="preferences" />
    </el-tab-pane>
    <el-tab-pane
      v-if="features.includes('ATTRIBUTES')"
      class="settings-panel"
      :label="$t('settings.integration.menu.import')"
      name="3"
    >
      <div class="tab-header">
        <div class="tab-title">
          {{ $t('settings.integration.menu.import-title', { tool: name }) }}
        </div>
        <div class="tab-desc">
          {{ $t('settings.integration.menu.import-step', { tool: name }) }}
        </div>
      </div>
      <IntegrationSettingsAttributes :integration="integration" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
export default {
  name: 'integration-settings-menu',
  components: {
    IntegrationSettingsPreference: () =>
      import('./IntegrationSettingsPreference'),
    IntegrationSettingsAttributes: () =>
      import('./IntegrationSettingsAttributes'),
  },
  props: {
    features: {
      type: Array,
      required: true,
    },
    preferences: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    integration: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noInstall: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: '1',
    };
  },

  mounted() {
    this.setActiveTab();
  },
  methods: {
    setActiveTab() {
      if (this.noInstall) {
        this.activeTab = '2';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-panel {
  max-height: 75vh;
  overflow: auto;
}

::-webkit-scrollbar {
  display: none;
}

.tab-header {
  margin-bottom: 12px;
  .tab-title {
    color: black;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 6px;
  }
  .tab-desc {
    color: $grey-6-mayday;
    font-size: 12px;
  }

  .tab-button {
    margin-top: 20px;
  }

  .bold {
    font-weight: bold;
  }
}
</style>
