<template>
  <div class="results">
    <el-collapse accordion>
      <el-collapse-item
        v-for="message in queries"
        :key="message.queryId"
        class="accordion-item"
      >
        <template #title>
          <TextEllipsis
            :text="message.query.queryText"
            fontSize="14px"
          ></TextEllipsis>
        </template>
        <div>
          <div v-if="message.query">
            <div v-if="message.query.language">
              <span class="text-m"
                >{{ $t('ask.language') }} {{ message.query.language }}</span
              >
            </div>
            <div v-if="message.query.keywords">
              <span class="text-m">{{ $t('ask.keywords') }}</span>
              <ul v-for="(keyword, j) in message.query.keywords" :key="j">
                <li>{{ keyword }}</li>
              </ul>
            </div>
            <div v-if="message.query.search && message.query.search.length">
              <div
                v-for="(document, j) in message.query.search"
                :key="j"
                class="search-item"
              >
                <div
                  class="search-result-button"
                  @click="
                    $emit('search-result-click', { message, id: document.id })
                  "
                >
                  <article-type-icon :item="document"></article-type-icon>
                  <TextEllipsis
                    :text="document.label"
                    fontSize="14px"
                  ></TextEllipsis>
                </div>
                <span class="score-text"
                  >{{ $t('ask.score') }} {{ document.score }}</span
                >
                <details
                  v-if="message.contexts && message.contexts[document.id]"
                  class="score-text"
                >
                  <summary>
                    <span>{{ $t('ask.context') }}</span>
                  </summary>
                  <pre class="context-str">{{
                    message.contexts[document.id]
                  }}</pre>
                </details>
              </div>
            </div>
          </div>
          <div v-else>{{ $t('ask.no-result') }}</div>
          <button type="submit" @click="$emit('redo-query', message.queryId)">
            <font-awesome-icon :icon="['fas', 'redo-alt']" />
            {{ $t('ask.replay') }}
          </button>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import TextEllipsis from 'components/TextEllipsis.vue';
import ArticleTypeIcon from 'components/SuperSearch/ArticleTypeIcon.vue';

export default {
  name: 'AskResultsAccordion',
  components: {
    TextEllipsis,
    ArticleTypeIcon,
  },
  props: {
    queries: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
.results {
  height: 100%;
  flex: 1;
  overflow-y: auto;
}

.accordion-item > * > * > * {
  padding-left: 10px;
}

.search-result-button {
  display: flex;
  align-items: center;
  color: $purple-5-mayday;
  font-size: 8px;
  border-radius: 4px;
  flex: none;
  padding: 4px;
  border: solid 1px $purple-5-mayday;
  transition: background-color 100ms ease-in-out;

  &:hover {
    background-color: white;
    color: $purple-5-mayday;
    cursor: pointer;
  }
}

.score-text {
  color: $grey-6-mayday;
  font-size: 10px;
}

.context-str {
  border: solid 1px $grey-6-mayday;
  border-radius: 4px;
  padding: 4px;
  white-space: pre-wrap;
}

button {
  padding: 4px 8px;
  border: 1px solid $purple-5-mayday;
  border-radius: 4px;
  background-color: $purple-5-mayday;
  color: white;

  &:hover {
    background-color: $purple-4-mayday;
  }
}

.search-item {
  padding: 6px 12px;
  margin-bottom: 6px;
  margin-right: 6px;
  border-radius: 4px;
  background-color: $grey-2-mayday;
}
</style>
