<template>
  <div class="step">
    <div v-if="hasName" :class="['dot', { 'dot-success': this.success }]">
      {{ name }}
    </div>
    <div class="title mb-0">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: 'integration-settings-step',
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    name: {
      type: String,
    },
    success: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasName() {
      return this.name ? true : false;
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.step {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  .dot {
    padding-top: 1px;
    background-color: $grey-4-mayday;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    font-size: 16px;
    color: black;
    text-align: center;
  }

  .dot-success {
    background: rgba($green-mayday, 0.2);
    color: $green-mayday;
  }

  .title {
    margin-left: 10px;
    color: black;
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
