<template>
  <IntegrationSettingsMenu
    name="Notion"
    :features="features"
    :preferences="preferences"
    :integration="integration.value"
  >
    <!-- SLOT INSTALLATION STEPS -->
    <IntegrationSettingsStep
      name="1"
      :title="$t('settings.integration.notion.step-one.title')"
      :success="state.success"
    />
    <div class="step-container">
      <div class="step-item">
        <ol>
          <li>
            {{ $t('settings.integration.notion.step-one.one') }}
            <a href="https://www.notion.com/my-integrations" target="_blank">
              {{ $t('settings.integration.notion.step-one.one-link') }} </a
            >.
          </li>
          <li>
            {{ $t('settings.integration.notion.step-one.two') }}
          </li>
          <li>
            {{ $t('settings.integration.notion.step-one.two-desc') }}
          </li>
        </ol>
      </div>
    </div>
    <IntegrationSettingsStep
      name="2"
      :title="$t('settings.integration.notion.step-two.title')"
      :success="state.success"
    />
    <div class="step-container">
      <div class="step-item">
        <div class="step-action">
          <div class="step-input">
            <span class="label">{{
              $t('settings.integration.notion.step-two.token-label')
            }}</span>
            <el-input
              v-loading="state.loading"
              :placeholder="
                $t('settings.integration.notion.step-two.token-placeholder')
              "
              v-model="model.token"
              :disabled="state.success"
              @keyup.enter.native="initializeNotionAuth()"
            >
              <font-awesome-icon
                :icon="['fas', 'lock']"
                slot="prefix"
                class="step-input-icon"
              />
            </el-input>
          </div>
          <el-button
            v-if="state.success"
            type="primary"
            plain
            size="small"
            @click="modifyToken()"
            >{{
              $t('settings.integration.notion.step-two.token-modify')
            }}</el-button
          >
          <el-button
            v-else
            type="primary"
            plain
            size="small"
            @click="initializeNotionAuth()"
            >{{
              $t('settings.integration.notion.step-two.token-connect')
            }}</el-button
          >
        </div>
      </div>
    </div>
    <IntegrationSettingsStep
      name="3"
      :title="$t('settings.integration.notion.step-three.title')"
      :success="state.success"
    />
    <div class="step-container">
      <div class="step-item">
        {{ $t('settings.integration.notion.step-three.one') }}
      </div>
    </div>
  </IntegrationSettingsMenu>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import IntegrationSettingsStep from '@/components/Settings/IntegrationSettings/Integrations/IntegrationSettingsStep';
import IntegrationSettingsMenu from '@/components/Settings/IntegrationSettings/Integrations/IntegrationSettingsMenu';

export default {
  name: 'integration-settings-notion',
  components: {
    IntegrationSettingsStep,
    IntegrationSettingsMenu,
  },
  props: {
    integration: {
      type: Object,
      required: true,
    },
  },
  computed: {
    features() {
      return this.integration.features;
    },
    ...mapState(['companyId']),
  },
  data() {
    return {
      preferences: {},
      model: {
        token: '',
      },
      state: {
        success: false,
        loading: false,
      },
    };
  },
  methods: {
    ...mapActions(['initNotionAuth', 'checkAuthExists']),
    modifyToken() {
      this.model.token = '';
      this.state.success = false;
    },
    async initializeNotionAuth() {
      this.state.loading = true;
      const { token } = this.model;
      const res = await this.initNotionAuth({
        token,
      });
      this.state.loading = false;
      if (res) {
        this.state.success = true;
        return this.$message({
          message: this.$t('settings.integration.notion.step-two.success'),
          type: 'success',
        });
      }
      return this.$message({
        message: this.$t('settings.integration.notion.step-two.error'),
        type: 'error',
      });
    },
  },
  async created() {
    this.state.success = await this.checkAuthExists('notion');
  },
};
</script>

<style lang="scss" scoped>
.step-container {
  padding-left: 38px;

  .step-item {
    color: $grey-6-mayday;
    font-size: 12px;
    margin-bottom: 10px;
    .step-action {
      display: flex;
      .step-input {
        width: 100%;
      }
      button {
        margin-top: 18px;
        margin-left: 1rem;
        height: 34px;
      }
    }
    :deep() .el-input__inner {
      height: 34px;
    }
    ol {
      padding-left: 12px;
    }
    .step-info {
      border: 1px solid $grey-5-mayday;
      background-color: $grey-1-mayday;
      color: $grey-8-mayday;
      padding: 6px 10px;
      margin: 0;
      p {
        font-size: 12px;
        margin-bottom: 0;
        font-weight: bold;
      }
    }
    .step-input {
      margin-bottom: 10px;
      .label {
        font-weight: bold;
        color: black;
        padding: 8px 0px;
      }
      .step-input-icon {
        width: 16px;
        height: 16px;
        margin: 9px 4px;
      }
      .description {
        font-size: 12px;
        color: black;
      }
    }
  }
}
</style>
