<template>
  <div class="query-container">
    <div :class="['query', { expanded: !loading }, { error: error.message }]">
      {{ query.queryText }}
    </div>
    <div class="error-container" v-if="error.message">
      <div class="error-title">Error</div>
      <div
        v-if="!devMode || !error.message.message || !error.message.tb"
        class="error-message"
      >
        {{ error.message }}
      </div>
      <div v-else>
        <details>
          <summary>{{ error.message.message }}</summary>
          <pre>{{ error.message.tb.join('\n') }}</pre>
        </details>
      </div>
    </div>
    <div class="response-container" v-if="markdown && markdown.length > 0">
      <AskCallouts
        v-if="citedIds.length && callouts"
        :callouts="callouts"
        :hovered="hoveredLink"
        :citedIds="citedIds"
        @md-event="handleMarkdownEvent"
      ></AskCallouts>
      <AskMarkdownRenderer
        :hovered="hoveredLink"
        :markdown="markdown"
        :contexts="contexts"
        :links="links"
        @md-event="handleMarkdownEvent"
      ></AskMarkdownRenderer>
      <div class="response-links" v-if="links">
        <div class="links-container">
          <div
            v-for="(link, i) in sortedLinks.quoted"
            :key="i"
            :class="['link', { hovered: hoveredLink === link.id }]"
            @mouseover="hoveredLink = link.id"
            @mouseleave="hoveredLink = null"
            @click="goToDocument(link.id)"
          >
            <ask-source
              :linkId="link.id"
              :isOutdated="isOutdated(link.id)"
              :citationLabels="citationLabels"
              :contextDisabled="contextDisabled(link.id)"
              :defaultLanguage="link.defaultLanguage"
              :translations="link.translations"
              :contexts="contexts"
            ></ask-source>
          </div>
        </div>
      </div>
      <details
        class="infos-container"
        v-if="infos.lastChunkTime && devMode"
        content="Additional data"
      >
        <summary>Additional data</summary>
        <div>
          {{ $t('ask.dev.time-to-first-chunk') }} {{ timeRequestToFirstChunk }}s
        </div>
        <div>
          {{ $t('ask.dev.time-to-first-text-chunk') }}
          {{ timeRequestToFirstTextChunk }}s
        </div>
        <div>
          {{ $t('ask.dev.total-streaming-time') }}
          {{ timeFirstChunkToLastChunk }}s
        </div>
        <div>
          {{ $t('ask.dev.total-request-time') }} {{ timeRequestToLastChunk }}s
        </div>
      </details>
      <details
        class="infos-container"
        v-if="sortedLinks.nonQuoted && sortedLinks.nonQuoted.length"
        content="Additional sources"
      >
        <summary>Additional sources</summary>
        <div class="response-links mt-2">
          <div class="links-container">
            <div
              v-for="(link, i) in sortedLinks.nonQuoted"
              :key="i"
              class="link"
              @click="goToDocument(link.id)"
            >
              <ask-source
                :linkId="link.id"
                :isOutdated="isOutdated(link.id)"
                :citationLabels="citationLabels"
                :contextDisabled="contextDisabled(link.id)"
                :defaultLanguage="link.defaultLanguage"
                :translations="link.translations"
                :contexts="contexts"
              ></ask-source>
            </div>
          </div>
        </div>
      </details>
      <ask-feedback-popover
        :feedback="feedback"
        :citedIds="citedIds"
        @send-feedback="handleFeedback"
      ></ask-feedback-popover>
    </div>
    <div v-if="loading">
      <div class="loading-container">
        <div class="loading-dot"></div>
        <div class="loading-dot"></div>
        <div class="loading-dot"></div>
      </div>
    </div>
  </div>
</template>

<script>
import AskMarkdownRenderer from './AskMarkdownRenderer.vue';
import AskSource from './AskSource.vue';
import AskCallouts from './AskCallouts.vue';
import AskFeedbackPopover from './AskFeedbackPopover.vue';

export default {
  name: 'AskQuery',
  components: {
    AskMarkdownRenderer,
    AskSource,
    AskCallouts,
    AskFeedbackPopover,
  },
  props: {
    query: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    callouts: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    response: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: '',
    },
    markdown: {
      type: String,
      default: () => '',
    },
    error: {
      type: Object,
      default: () => ({}),
    },
    links: {
      type: Array,
      default: () => [],
    },
    citationLabels: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    feedback: {
      type: String,
      default: null,
    },
    infos: {
      type: Object,
      default: () => ({}),
    },
    devMode: {
      type: Boolean,
      default: false,
    },
    contexts: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    hoveredLink: null,
    sortedLinks: { quoted: [], nonQuoted: [] },
  }),
  computed: {
    timeRequestToFirstChunk() {
      return (this.infos.firstChunkTime - this.infos.requestTime) / 1000;
    },
    timeRequestToLastChunk() {
      return (this.infos.lastChunkTime - this.infos.requestTime) / 1000;
    },
    timeRequestToFirstTextChunk() {
      return (this.infos.firstTextChunkTime - this.infos.requestTime) / 1000;
    },
    timeFirstChunkToLastChunk() {
      return (this.infos.lastChunkTime - this.infos.firstChunkTime) / 1000;
    },
    infoTooltipContent() {
      return `Request to first chunk: ${this.timeRequestToFirstChunk}s |
      Request to last chunk: ${this.timeRequestToLastChunk}s |
      First chunk to last chunk: ${this.timeFirstChunkToLastChunk}s`;
    },
    citedIds() {
      return this.sortedLinks.quoted.map((link) => link.id);
    },
  },
  methods: {
    contextDisabled(linkId) {
      return (
        !this.contexts ||
        !this.contexts[linkId] ||
        this.contexts[linkId] === '[]'
      );
    },
    isOutdated(id) {
      const document = this.links.find((d) => d.id === id);
      return document && document.isOutdated;
    },
    handleFeedback(feedbackForm) {
      this.displayFeedbackPopover = false;
      this.$emit(
        'feedback',
        {
          rating: feedbackForm.rating,
          content: {
            text: feedbackForm.message,
            citations: feedbackForm.citations,
            language: feedbackForm.language,
          },
          answer: this.markdown,
        },
        this.query,
        this.id,
      );
    },
    goToDocument(id) {
      const document = this.links.find((d) => d.id === id);
      this.$emit('go-to-document', document);
    },
    handleMarkdownEvent(event) {
      if (event.type == 'leave') {
        this.hoveredLink = null;
      } else if (event.type == 'hover') {
        this.hoveredLink = event.id;
      } else if (event.type == 'go-to-document') {
        this.goToDocument(event.id);
      }
    },
    computeSortedLinks() {
      this.sortedLinks = this.links.reduce(
        (acc, val) => {
          if (this.citationLabels[val.id]) acc.quoted.push(val);
          else acc.nonQuoted.push(val);
          return acc;
        },
        { quoted: [], nonQuoted: [] },
      );
    },
  },
  watch: {
    citationLabels: {
      handler() {
        this.computeSortedLinks();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.query-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  max-width: 100%;
}

.query {
  font-size: 14px;
  font-weight: 600;
  background-color: $purple-5-mayday;
  color: white;
  padding: 8px 16px;
  border-radius: 8px;
  margin-bottom: 8px;
  transition: all 0.2s ease;
  width: fit-content;

  &.expanded {
    border-radius: 8px 8px 0 0;
    margin-bottom: -8px;
    padding-bottom: 16px;
  }

  &.error {
    background-color: $red-5-mayday;
  }
}

.error-container {
  background-color: $red-1-mayday;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  position: relative;
}

.response-container {
  background-color: $grey-2-mayday;
  border-radius: 8px;
  padding: 8px 16px 16px 16px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  position: relative;
}

.response-text {
  .link {
    color: $purple-5-mayday;
    font-size: 8px;
    border: 1px solid $purple-5-mayday;
    border-radius: 4px;
    padding: 2px 4px;
    background-color: white;
    cursor: pointer;
    transition: all 0.2s ease;

    &.hovered {
      background-color: $purple-5-mayday;
      color: white;
    }
  }
}

.response-links {
  gap: 4px;
  display: flex;
  align-items: center;
  width: 100%;

  .links-container {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
    overflow: auto;
  }

  .link {
    display: flex;
    align-items: center;
    gap: 4px;
    border: 1px solid $purple-5-mayday;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 12px;
    color: $purple-5-mayday;
    cursor: pointer;
    transition: all 0.2s ease;
    max-width: fit-content;
    overflow: hidden;
    flex: 1;
    position: relative;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.hovered {
      background-color: $purple-5-mayday;
      color: white;
    }
  }
}

.infos-container {
  color: $grey-6-mayday;
  font-size: 10px;
}

.loading-container {
  display: flex;
  align-items: center;
  gap: 4px;
  padding-top: 16px;
}

.loading-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $grey-5-mayday;
  animation: loading 1s infinite ease-in-out;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }
}

@keyframes loading {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
