<template>
  <div>
    <el-tag
      :key="tag.id"
      v-for="tag in tagStatuses"
      closable
      :type="tag.relevant ? 'success' : 'danger'"
      :disable-transitions="false"
      @close="handleClose(tag)"
    >
      {{ tag.id }}
    </el-tag>
    <el-input
      class="input-new-tag"
      v-if="inputVisible"
      v-model="inputValue"
      ref="saveTagInput"
      size="mini"
      @keyup.enter.native="handleInputConfirm"
      @blur="handleInputConfirm"
    >
    </el-input>
    <el-button v-else class="button-new-tag" size="small" @click="showInput"
      >+ Add document</el-button
    >
  </div>
</template>

<script>
export default {
  props: {
    citedIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      inputVisible: false,
      inputValue: '',
      tagStatuses: this.citedIds.map((id) => ({
        id,
        relevant: true,
        cited: true,
      })),
    };
  },
  methods: {
    handleClose(tag) {
      if (tag.cited) {
        this.tagStatuses
          .filter((d) => d.id === tag.id)
          .map((d) => (d.relevant = !d.relevant));
      } else {
        this.tagStatuses.splice(this.tagStatuses.indexOf(tag), 1);
      }
      this.$emit('update-citations', this.tagStatuses);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (!inputValue) {
        return;
      }
      if (
        inputValue &&
        /^[0-9a-fA-F]{24}$/.test(inputValue) &&
        !this.tagStatuses.some((d) => d.id === inputValue)
      ) {
        this.tagStatuses.push({ id: inputValue, relevant: true, cited: false });
      } else {
        this.$message.error('Invalid ID');
        return;
      }
      this.inputVisible = false;
      this.inputValue = '';
      this.$emit('update-citations', this.tagStatuses);
    },
  },
};
</script>

<style scoped lang="scss">
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
