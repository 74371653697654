<template>
  <IntegrationSettingsMenu
    name="Mayday Desk"
    :features="features"
    :preferences="preferences"
    :disabled="disabled"
    :integration="integration.value"
    :noInstall="noInstall"
  >
    <!-- SLOT INSTALLATION STEPS -->
  </IntegrationSettingsMenu>
</template>
<script>
import IntegrationSettingsMenu from '@/components/Settings/IntegrationSettings/Integrations/IntegrationSettingsMenu';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'integration-settings-mayday-web',
  components: {
    IntegrationSettingsMenu,
  },
  props: {
    integration: {
      type: Object,
      required: true,
    },
  },
  computed: {
    features() {
      return this.integration.features;
    },
    ...mapGetters('adminUserDirectoryModule', ['integrationStatus']),
    ...mapGetters([
      'companyColor',
      'companyMaydayWebPrimaryColor',
      'companyMaydayWebSecondaryColor',
      'companyMaydayWebIcon',
      'companyLogo',
      'isPluginPreferenceAllowed',
    ]),
  },
  data() {
    return {
      preferences: {},
      disabled: true,
      noInstall: true,
      pluginIntegrationName: 'Desk',
    };
  },
  methods: {
    openLink(url) {
      window.open(url, '_blank');
    },
    ...mapActions('adminModule', ['getMe']),
  },
  mounted() {
    this.preferences = {
      MAYDAY_WEB_SEARCH_NO_STEPS: {
        title: this.$t(
          'settings.card-settings-options.integration-search.title',
        ),
        description: this.$t(
          'settings.card-settings-options.integration-search.description',
        ),
        iconName: ['fas', 'search'],
        notification: this.$t(
          'settings.card-settings-options.integration-search.notification',
        ),
        hasRule: false,
      },
      MAYDAY_WEB_PRIMARY_COLOR: {
        title: this.$t(
          'settings.card-settings-options.integration-primary-color.title',
        ),
        description: `${this.$t(
          'settings.card-settings-options.integration-primary-color.description',
        )}  ${this.$t('settings.integration.mayday-web.inlineLabel')}`,
        isColor: true,
        defaultValue: this.companyColor,
        value: this.companyMaydayWebPrimaryColor,
        iconName: ['fas', 'palette'],
        notification: this.$t(
          'settings.card-settings-options.integration-primary-color.notification',
        ),
      },
      MAYDAY_WEB_SECONDARY_COLOR: {
        title: this.$t(
          'settings.card-settings-options.integration-secondary-color.title',
        ),
        description: `${this.$t(
          'settings.card-settings-options.integration-secondary-color.description',
        )}  ${this.$t('settings.integration.mayday-web.inlineLabel')}`,
        isColor: true,
        defaultValue: this.companyColor,
        value: this.companyMaydayWebSecondaryColor,
        iconName: ['fas', 'palette'],
        notification: this.$t(
          'settings.card-settings-options.integration-secondary-color.notification',
        ),
      },
      MAYDAY_WEB_ICON: {
        title: this.$t('settings.card-settings-options.integration-icon.title'),
        description: `${this.$t(
          'settings.card-settings-options.integration-icon.description',
        )}  ${this.$t('settings.integration.mayday-web.inlineLabel')}`,
        iconName: ['fas', 'image'],
        notification: this.$t(
          'settings.card-settings-options.integration-icon.notification',
        ),
        isImage: true,
        hasRule: false,
        defaultValue: '',
      },
      MAYDAY_WEB_MAIN_PAGE_COVER: {
        title: this.$t(
          'settings.card-settings-options.integration-banner.title',
        ),
        description: `${this.$t(
          'settings.card-settings-options.integration-banner.description',
        )}  ${this.$t('settings.integration.mayday-web.inlineLabel')}`,
        iconName: ['fas', 'image'],
        notification: this.$t(
          'settings.card-settings-options.integration-banner.notification',
        ),
        isImage: true,
        hasRule: false,
        defaultValue: this.companyMaydayWebBanner,
        width: '1362',
        height: '331',
        assetType: 'banner',
      },
      MAYDAY_WEB_HIDE_CASES_DESCRIPTION: {
        title: this.$t(
          'settings.card-settings-options.integration-cases-description.title',
        ),
        description: `${this.$t(
          'settings.card-settings-options.integration-cases-description.description',
        )}  ${this.$t('settings.integration.mayday-web.inlineLabel')}`,
        iconName: ['fas', 'align-left'],
        notification: this.$t(
          'settings.card-settings-options.integration-cases-description.notification',
        ),
        hasRule: false,
      },
      ALLOW_EMBEDDED_IFRAME: {
        title: this.$t(
          'settings-layout.menu-data.plugins.embedded-iframe.title',
        ),
        description: `${this.$t(
          'settings-layout.menu-data.plugins.embedded-iframe.short-description',
        )}`,
        iconName: ['fal', 'code'],
        value: this.integrationStatus,
        hasRule: false,
        pluginIntegration: this.pluginIntegrationName,
        disabled: !this.isPluginPreferenceAllowed('ALLOW_EMBEDDED_IFRAME'),
        pluginType: 'EmbeddedIframeEntity',
      },
      ALLOW_TRENDING_CONTENT: {
        title: this.$t(
          'settings.card-settings-options.common.plugin.trending-contents.title',
        ),
        description: `${this.$t(
          'settings.card-settings-options.common.plugin.trending-contents.description',
        )}`,
        iconName: ['fal', 'arrow-trend-up'],
        value: this.integrationStatus,
        hasRule: false,
        pluginIntegration: this.pluginIntegrationName,
        disabled: !this.isPluginPreferenceAllowed('ALLOW_TRENDING_CONTENT'),
        pluginType: 'TrendingContentEntity',
      },
      DESK_NEW_CONTENTS_PLUGIN: {
        title: this.$t(
          'settings.card-settings-options.common.plugin.new-contents.title',
        ),
        description: `${this.$t(
          'settings.card-settings-options.common.plugin.new-contents.description',
        )}`,
        iconName: ['fas', 'align-left'],
        notification: this.$t(
          'settings.card-settings-options.common.plugin.new-contents.notification',
        ),
        value: this.integrationStatus,
        hasRule: false,
        pluginIntegration: this.pluginIntegrationName,
        disabled: !this.isPluginPreferenceAllowed('DESK_NEW_CONTENTS_PLUGIN'),
        pluginType: 'NewContentEntity',
      },
      ALLOW_SMART_ACTIONS: {
        title: this.$t(
          'settings.card-settings-options.common.plugin.smart-actions.title',
        ),
        description: `${this.$t(
          'settings.card-settings-options.common.plugin.smart-actions.description',
        )}`,
        iconName: ['fas', 'arrow-up-right-from-square'],
        notification: this.$t(
          'settings.card-settings-options.common.plugin.smart-actions.notification',
        ),
        value: this.integrationStatus,
        hasRule: false,
        pluginIntegration: this.pluginIntegrationName,
        disabled: !this.isPluginPreferenceAllowed('ALLOW_SMART_ACTIONS'),
        pluginType: 'SmartActionEntity',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.step-container {
  .step-item {
    color: $grey-6-mayday;
    font-size: 12px;
    margin-bottom: 10px;
  }
}
</style>
