<template>
  <el-tooltip effect="light" :disabled="contextDisabled">
    <div slot="content" class="label-tooltip">
      <div v-if="isOutdated" class="outdated-warning">
        {{ $t('ask.document-outdated') }}
      </div>
      <div>{{ context }}</div>
    </div>
    <div>
      <span>{{ fullLabel }}</span>
      <span v-if="isOutdated" class="outdated-marker-container">
        <span class="red-circle"></span
      ></span>
    </div>
  </el-tooltip>
</template>

<script>
export default {
  name: 'ask-source',
  components: {},
  props: {
    linkId: {
      type: String,
      default: '',
    },
    citationLabels: {
      type: Object,
      default: () => ({}),
    },
    contexts: {
      type: Object,
      default: () => ({}),
    },
    isOutdated: {
      type: Boolean,
      required: true,
    },
    contextDisabled: {
      type: Boolean,
      required: true,
    },
    defaultLanguage: {
      type: String,
      required: true,
    },
    translations: {
      type: Object,
      default: () => ({}),
    },
    language: {
      type: String,
      default: null,
    },
  },
  computed: {
    translatedLabel() {
      if (this.translations) {
        if (
          this.language &&
          this.translations[this.language] &&
          this.translations[this.language].label
        )
          return this.translations[this.language].label;
        if (
          this.defaultLanguage &&
          this.translations[this.defaultLanguage] &&
          this.translations[this.defaultLanguage].label
        ) {
          return this.translations[this.defaultLanguage].label;
        }
      }
      return '';
    },
    fullLabel() {
      return (
        (this.citationLabels && this.citationLabels[this.linkId]
          ? this.citationLabels[this.linkId] + '. '
          : '') + this.translatedLabel
      );
    },
    context() {
      return this.contexts[this.linkId];
    },
  },
};
</script>

<style scoped lang="scss">
.outdated-warning {
  color: $red-5-mayday;
}

.outdated-marker-container {
  width: 8px;
  height: 8px;
  position: relative;
  display: inline-block;
  margin-left: 4px;
}

.red-circle {
  width: 6px;
  height: 6px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 1px;
  bottom: 1px;
}

.label-tooltip {
  max-width: 300px;
}
</style>
