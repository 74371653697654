<template>
  <div :class="['feedbacks-container', { disabled: feedback }]">
    <div
      :class="['upvote', { selected: feedback === 'up' }]"
      @click="handleFeedbackUp"
    >
      <font-awesome-icon
        :icon="[feedback === 'up' ? 'fas' : 'fal', 'thumbs-up']"
      />
    </div>
    <el-popover
      placement="bottom-end"
      width="350"
      v-model="displayFeedbackPopover"
    >
      <div
        slot="reference"
        :class="['downvote', { selected: feedback === 'down' }]"
      >
        <font-awesome-icon
          :icon="[feedback === 'down' ? 'fas' : 'fal', 'thumbs-down']"
        />
      </div>
      <div class="feedback-form">
        <el-tabs v-model="activeTab">
          <el-tab-pane label="Answer" name="answer">Answer</el-tab-pane>
          <el-tab-pane label="Documents" name="documents"
            >Documents</el-tab-pane
          >
          <el-tab-pane label="Language" name="language">Language</el-tab-pane>
        </el-tabs>
        <div v-if="activeTab === 'answer'">
          <el-input
            v-model="feedbackForm.message"
            placeholder="Feedback message"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
          />
        </div>
        <div v-else-if="activeTab === 'documents'">
          <ask-feedback-docs-picker
            :citedIds="citedIds"
            @update-citations="handleCitationsUpdate"
          />
        </div>
        <div v-else-if="activeTab === 'language'">
          <el-select v-model="feedbackForm.language" placeholder="language">
            <el-option
              v-for="option in supportedLanguages"
              :key="option.id"
              :label="option.label"
              :value="option.id"
            >
            </el-option>
          </el-select>
        </div>
        <el-button
          type="primary"
          @click="handleFeedbackDown"
          :disabled="
            !feedbackForm.message && !citationsChanged && !feedbackForm.language
          "
          >Send</el-button
        >
      </div>
    </el-popover>
  </div>
</template>

<script>
import AskFeedbackDocsPicker from './AskFeedbackDocsPicker.vue';
export default {
  components: { AskFeedbackDocsPicker },
  name: 'AskFeedbackPopover',
  props: {
    feedback: {
      type: String,
      default: null,
    },
    citedIds: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleFeedbackUp() {
      this.$emit('send-feedback', { rating: 'up' });
    },
    handleFeedbackDown() {
      this.$emit('send-feedback', { ...this.feedbackForm, rating: 'down' });
      this.displayFeedbackPopover = false;
    },
    handleCitationsUpdate(tagStatuses) {
      this.citationsChanged = true;
      this.feedbackForm.citations = tagStatuses;
    },
  },
  data: () => ({
    citationsChanged: false,
    activeTab: 'answer',
    displayFeedbackPopover: false,
    supportedLanguages: [
      { id: 'en', label: 'English' },
      { id: 'es', label: 'Spanish' },
      { id: 'fr', label: 'French' },
      { id: 'de', label: 'German' },
      { id: 'it', label: 'Italian' },
      { id: 'pt', label: 'Portuguese' },
      { id: 'ru', label: 'Russian' },
      { id: 'ja', label: 'Japanese' },
      { id: 'zh', label: 'Chinese' },
      { id: 'ar', label: 'Arabic' },
      { id: 'ko', label: 'Korean' },
      { id: 'nl', label: 'Dutch' },
      { id: 'pl', label: 'Polish' },
      { id: 'sv', label: 'Swedish' },
      { id: 'tr', label: 'Turkish' },
      { id: 'vi', label: 'Vietnamese' },
    ],
    feedbackForm: {
      message: null,
      citations: null,
      type: 'product',
      language: null,
      options: [
        {
          label: 'Product',
          value: 'product',
        },
        {
          label: 'AI',
          value: 'ai',
        },
      ],
    },
  }),
};
</script>

<style scoped lang="scss">
.feedbacks-container {
  display: flex;
  align-items: center;
  position: absolute;
  background-color: white;
  overflow: hidden;
  border: 1px solid $grey-2-mayday;
  border-radius: 8px;
  bottom: -8px;
  right: 8px;
  font-size: 10px;
  color: $grey-6-mayday;

  &.disabled {
    pointer-events: none;
  }

  .upvote {
    cursor: pointer;
    padding: 2px 4px 2px 6px;
    &:hover,
    &.selected {
      color: $green-5-mayday;
    }
  }

  .downvote {
    cursor: pointer;
    padding: 2px 6px 2px 4px;

    &:hover,
    &.selected {
      color: $red-5-mayday;
    }
  }
}
.feedback-form {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 16px;
}
</style>
