<template>
  <div class="options-container">
    <div class="options">
      <div
        class="option"
        v-for="(optionSpec, i) in optionSpecs"
        :key="optionSpec.key || i"
      >
        <div class="option" v-if="optionSpec.type == 'section'">
          <div class="section_title">{{ optionSpec.label }}</div>
        </div>
        <div class="option" v-if="optionSpec.type == 'choice'">
          <div class="option_title">{{ optionSpec.label }}</div>
          <div class="option_content">
            <select
              v-model="selectedAskParameters[optionSpec.key]"
              :disabled="!optionSpec.enabled"
            >
              <option
                v-for="opt in optionSpec.values"
                :value="opt.key"
                :key="opt.value"
              >
                {{ opt.label }}
              </option>
            </select>
          </div>
        </div>
        <div
          class="option"
          v-if="optionSpec.type == 'integer'"
          :disabled="!optionSpec.enabled"
        >
          <div class="option_title">{{ optionSpec.label }}</div>
          <div class="option_content">
            <input v-model="selectedAskParameters[optionSpec.key]" />
          </div>
        </div>
        <div class="option" v-if="optionSpec.type == 'checkbox'">
          <el-checkbox
            v-model="selectedAskParameters[optionSpec.key]"
            :label="optionSpec.label"
            :disabled="!optionSpec.enabled"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
function createObjectFromPaths(paths) {
  const obj = {};
  Object.entries(paths).forEach(([path, value]) => {
    if (value === null) {
      return;
    }
    const parts = path.split('.');
    let current = obj;
    for (let i = 0; i < parts.length - 1; i++) {
      const part = parts[i];
      if (!(part in current)) {
        current[part] = {};
      }
      current = current[part];
    }
    current[parts[parts.length - 1]] = value;
  });
  return obj;
}

export default {
  name: 'ask-parameters',
  data: () => ({
    optionSpecs: [],
    selectedAskParameters: {},
  }),
  async created() {
    this.optionSpecs = (
      await this.$services.brainClient.getParameters()
    ).data;
    this.selectedAskParameters = Object.fromEntries(
      this.optionSpecs
        .filter((d) => d.key)
        .map((d) => {
          if (d.type === 'choice') {
            const defaultValue = d.values.find((d) => d.default);
            if (defaultValue) {
              return [d.key, defaultValue.key];
            }
          }
          return [d.key, d.default];
        }),
    );
  },
  watch: {
    selectedAskParameters: {
      handler: async function (value) {
        const options = createObjectFromPaths(value);
        const result = await this.$services.brainClient.validateParameters(
          options,
        );
        this.optionSpecs = result.data.parameters;
        Object.keys(value).forEach((key) => {
          if (result.data.updates[key] !== undefined) {
            value[key] = result.data.updates[key];
          }
        });
        this.$emit(
          'update-ask-parameters',
          createObjectFromPaths(this.selectedAskParameters),
        );
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.options-container {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .options {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .option {
      display: flex;
      flex-direction: column;
      margin-bottom: 5px;
      .section_title {
        font-size: 18px;
        font-weight: 700;
        color: $grey-8-mayday;
        border-top: 1px solid lightgray;
      }
      .option_title {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 5px;
      }
      .option_content {
        display: flex;
        flex-direction: column;
        select {
          padding: 5px;
          border: 1px solid $grey-2-mayday;
          border-radius: 5px;
          font-size: 14px;
          font-weight: 400;
          background-color: white;
        }
      }
    }
  }
}
</style>
