import { render, staticRenderFns } from "./IntegrationSettingsMenu.vue?vue&type=template&id=a6d1af62&scoped=true&"
import script from "./IntegrationSettingsMenu.vue?vue&type=script&lang=js&"
export * from "./IntegrationSettingsMenu.vue?vue&type=script&lang=js&"
import style0 from "./IntegrationSettingsMenu.vue?vue&type=style&index=0&id=a6d1af62&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6d1af62",
  null
  
)

export default component.exports